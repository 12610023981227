import React, { useEffect } from 'react'
import classNames from 'classnames'

import cl from './Overlay.module.css'

function Overlay({ children, callback = null, modalIsOpen, setIsOpen, ...props }) {
	const overlay = classNames(cl.overlay, modalIsOpen && cl.active)
	useEffect(() => {
		if (modalIsOpen) {
			document.body.style.overflow = 'hidden'
		} else {
			document.body.style.overflow = 'visible'
		}
	}, [modalIsOpen]);

	useEffect(() => {
		return () => {
			document.body.style.overflow = 'visible'
		}
	}, [])

	const onOverlayClick = (e) => {
		setIsOpen(false)
		callback && callback()
	}
	return (
		<div className={overlay} onClick={onOverlayClick} {...props}>
			{children}
		</div>
	)
}

export default Overlay
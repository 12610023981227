import React, { useEffect, useState } from 'react'
import { uid } from 'uid';
import classNames from 'classnames'

import cl from './Input.module.css'
import Spinner from '../spinner/Spinner';
import Tooltip from '../tooltip/Tooltip';

function Input({ value, onChange, className, helperText = ' ', err = '', loading = false, type = 'text', label = '', disabled = false, tooltip, rows = 2, ...props }) {
	const [error, setError] = useState(false)
	const [id, setId] = useState(uid())
	const [showPas, setShowPas] = useState(false)
	useEffect(() => {
		if (err) {
			setError(true)
		}
		if (!err) {
			setError(false)
		}
	}, [err])

	const toolT = <div className={cl.tooltip__info}>
		<i data-tip data-for={id} className='icon-circle-info' />
		<Tooltip id={id}>{tooltip}</Tooltip>
	</div>

	const labelFrag = label ? <label className={classNames(cl.label, disabled && cl.disabled)} htmlFor={id}>{label}{tooltip && toolT}</label> : null
	const helperTextFrag = helperText || err ? <span className={classNames(cl.helper, error && cl.error)}>{helperText}</span>
		: <span className={cl.helper}></span>

	const loadingIcon = <div className={cl.icon}><Spinner width={18} /></div>

	const textInput = <React.Fragment>
		{labelFrag}
		<div className={classNames(cl.inputWrap, className)}>
			<input {...props} disabled={disabled || loading} value={value} onChange={onChange} className={classNames(cl.input, error && cl.error)} placeholder={props.placeholder} id={id} type={type} />
			{loading && loadingIcon}
		</div>
		{helperTextFrag}
	</React.Fragment>

	const passwordInput = <React.Fragment>
		{labelFrag}
		<div className={classNames(cl.inputWrap, className)}>
			<input value={value} disabled={disabled} onChange={onChange} type={showPas ? 'text' : 'password'} className={classNames(cl.input, cl.password, error && cl.error)} id={id} {...props} />
			{showPas
				? <i onClick={() => !disabled && setShowPas(!showPas)} className={classNames('icon-eye_on', cl.icon)}></i>
				: <i onClick={() => !disabled && setShowPas(!showPas)} className={classNames('icon-eye_off', cl.icon)}></i>
			}
		</div>
		{helperTextFrag}
	</React.Fragment>

	const textareaInput = <React.Fragment>
		{labelFrag}
		<div className={classNames(cl.inputWrap, className)}>
			<textarea {...props} disabled={disabled || loading} value={value} onChange={onChange} className={classNames(cl.input, error && cl.error)} placeholder={props.placeholder} id={id} rows={rows} />
			{loading && loadingIcon}
		</div>
		{helperTextFrag}
	</React.Fragment>

	return (
		(type === 'text' && textInput) || (type === 'password' && passwordInput) || (type === 'area' && textareaInput) || textInput
	)
}

export default Input
import React from 'react'

import cl from './GoogleBtn.module.css'

function GoogleBtn({ text = 'Continue with Google', ...props }) {
	return (
		<button className={cl.google__btn}  {...props}>
			<div className={cl.img}>
				<img src="https://img.icons8.com/color/48/000000/google-logo.png" alt="google logo" />
			</div>
			<span>{text}</span>
		</button>
	)
}

export default GoogleBtn
import {
	createAsyncThunk,
	createEntityAdapter,
	createSlice,
} from "@reduxjs/toolkit";
import { StatisticVRSessionsServices } from "../../services/StatisticVRSessionsServices";
import { uid } from "uid";


function calculateRating(finalScore, maxScore) {
	const percentage = (finalScore / maxScore) * 100;
	const rating = (percentage / 100) * 5;
	return rating.toFixed(1);
}

const statisticVRSessionsAdapter = createEntityAdapter({
	selectId: (entity) => uid(8),
});

export const fetchStatisticVRSessions = createAsyncThunk(
	"statisticVRSessions/fetchStatisticVRSessions",
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem("token");
		const { comID, trainingId } = data;
		try {
			const response = await StatisticVRSessionsServices.getVRTrainingSessions(
				token,
				comID,
				trainingId
			);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
);

const StatisticVRSessionsSlice = createSlice({
	name: "statisticVRSessions",
	initialState: statisticVRSessionsAdapter.getInitialState({
		loading: true,
		error: "",
		sessions: null,
	}),
	reducers: {
		setError: (state, action) => {
			state.error = action.payload;
		},
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		setSessions: (state, action) => {
			state.sessions = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchStatisticVRSessions.pending, (state) => {
			state.loading = true;
			state.error = "";
		})
			.addCase(fetchStatisticVRSessions.fulfilled, (state, action) => {
				state.loading = false;

				let sessions = action.payload.data.sessions.map(session => {
					if (session.finalScore === 'n/a') {
						return {
							...session,
							rating: 'n/a'
						}
					} else {
						return {
							...session,
							rating: calculateRating(session.finalScore, session.maxScore)
						}

					}
				})

				let trainingData = {...action.payload.data, sessions}

				if (action.meta.arg.trainingId == 101) {
					trainingData.name = "Feeder Protection Relay Training for Operators";
				}
				state.sessions = {
					...state.sessions,
					[action.meta.arg.trainingId]: trainingData
				};

			})
			.addCase(fetchStatisticVRSessions.rejected, (state, action) => {
				state.error = "Session not found";
				state.loading = false;
			});
	},
});

export const { setError, setLoading, setSessions } = StatisticVRSessionsSlice.actions;
export default StatisticVRSessionsSlice.reducer;

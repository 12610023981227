import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { uid } from "uid";
import { StatisticPersonalService } from "../../services/StatisticPersonalServices";
import { newPrepareDataForChartByDays, prepareDataForChartByDays, prepareDataForChartByMonth, prepareDataForChartByWeek } from "../../utils/DataForStatistic";



export const getPlatformTimeUsers = createAsyncThunk(
	'statisticPersonalOverview/getPlatformTimeUsers',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		const { userId, comID } = data;
		try {
			const response = await StatisticPersonalService.getPlatformTimeUser(token, comID, userId);
			;
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getVideoTimeUsers = createAsyncThunk(
	'statisticPersonalOverview/getVideoTimeUsers',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		const { userId, comID } = data;
		try {
			const response = await StatisticPersonalService.getVideoTimeUser(token, comID, userId);
			;
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getVrTimeUsers = createAsyncThunk(
	'statisticPersonalOverview/getVrTimeUsers',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		const { userId, comID } = data;
		try {
			const response = await StatisticPersonalService.getVrTimeUser(token, comID, userId);
			;
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)


export const getPlatformSpentTime365 = createAsyncThunk(
	'statisticPersonalOverview/getPlatformSpentTime365',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		const { userId, comID } = data;
		try {
			const response = await StatisticPersonalService.getPlatformSpentTime365(token, comID, userId);
			return response.data.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)


export const sendWebSession = createAsyncThunk(
	'statisticPersonalOverview/sendWebSession',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		const { userId, comId, timeEnd, duration } = data;
		try {
			const response = await StatisticPersonalService.sendWebSession(token, userId, comId, timeEnd, duration);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)


const statisticPersonalOverviewAdapter = createEntityAdapter({
	selectId: (statistic) => uid(8)
})

const statisticPersonalOverviewSlice = createSlice({
	name: 'statisticPersonalOverview',
	initialState: statisticPersonalOverviewAdapter.getInitialState({
		loading: false,
		error: null,
		dailyStatistic: [],
		weeklyStatistic: [],
		monthlyStatistic: [],
		platformTimeSpent: {
			title: 'Platform Time Spent',
			color: "#4BC0A3",
			loading: true,
			error: null,
			365: null,
			90: null,
			30: null,
			7: null,
			ticks: null,
			ticksMob: null
		},
		platformTime: null,
		trainingTime: null,
		viewingTime: null,
	}),
	extraReducers: (builder) => {
		builder
			.addCase(getPlatformTimeUsers.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(getPlatformTimeUsers.fulfilled, (state, action) => {
				state.loading = false;
				state.platformTime = action.payload.data

			})
			.addCase(getPlatformTimeUsers.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getVideoTimeUsers.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(getVideoTimeUsers.fulfilled, (state, action) => {
				state.loading = false;
				state.viewingTime = action.payload.data;
			})
			.addCase(getVideoTimeUsers.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getVrTimeUsers.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(getVrTimeUsers.fulfilled, (state, action) => {
				state.loading = false;
				state.trainingTime = action.payload.data;
			})
			.addCase(getVrTimeUsers.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(sendWebSession.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(sendWebSession.fulfilled, (state, action) => {
				state.loading = false;
			})
			.addCase(sendWebSession.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getPlatformSpentTime365.pending, (state, action) => {
				state.platformTimeSpent.loading = true;
			})
			.addCase(getPlatformSpentTime365.fulfilled, (state, action) => {
				
				let obj = newPrepareDataForChartByDays(action.payload)
				if(!obj) return
				state.platformTimeSpent[365] = obj[365]
				state.platformTimeSpent[90] = obj[90]
				state.platformTimeSpent[30] = obj[30]
				state.platformTimeSpent[7] = obj[7]
				state.platformTimeSpent.error = null
				state.platformTimeSpent.ticks = obj.ticks
				state.platformTimeSpent.ticksMob = obj.ticksMob
				state.platformTimeSpent.loading = false
			})
			.addCase(getPlatformSpentTime365.rejected, (state, action) => {
				state.platformTimeSpent.loading = false
				state.platformTimeSpent.error = action.payload
			})
	}
})


export default statisticPersonalOverviewSlice.reducer;
import classNames from 'classnames';

import Overlay from '../overlay/Overlay';
import cl from './Modal.module.css'

import close from '../../assets/icons/close.svg'

function Modal({ modalIsOpen, setIsOpen, children, propagination = true, callback = () => { }, closeicon = false, ...props }) {
	const onCloseModal = () => {
		setIsOpen(false)
		callback && callback()
	}
	return (
		<Overlay callback={callback} modalIsOpen={modalIsOpen} setIsOpen={setIsOpen}>
			<div style={props.style} className={classNames(cl.modal, props.className)} onClick={(e) => propagination && e.stopPropagation()}>
				{children}
				{closeicon && <img className={cl.close} src={close} alt='Close modal' onClick={onCloseModal} />}
			</div>
		</Overlay>
	)
}

export default Modal
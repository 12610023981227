import React, { createContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { useIdleTimer } from 'react-idle-timer'

import { TABLET_WIDTH, useWindowWidth } from '../hooks/useWindowWidth'
import { fetchUserInfo } from '../redux/slices/UserInfoSlice'
import { checkToken } from '../redux/slices/LoginSlice'
import { sendWebSession } from '../redux/slices/StatisticPersonalOverviewSlice'

import { openMainDB } from '../db/mainDB'

import MobMenu from '../components/menu/MobMenu'
import PCMenu from '../components/menu/PCMenu'
import HelloModal from '../components/modal/home/HelloModal'
import VideosUploadOverlay from '../components/overlay/VideosUploadOverlay'
import VideosOverlay from '../components/overlay/VideosOverlay'

import cl from '../styles/pages/Home.module.css'
import 'react-toastify/dist/ReactToastify.css';
import NoticeOverlay from './home/NoticeOverlay'
import { getCompanyInfo } from '../redux/slices/CompanyInfoSlice'
import PCLivechat from '../components/livechat/PCLivechat'
import GuidedTour from '../components/instruction/GuidedTour'

export const vidDownloadingContext = createContext(
	{
		vidList: [],
		setVidList: () => { },
		downloading: {},
		setDownloading: () => { },
		vidsUpload: [],
		setVidsUpload: () => { },
		uploading: {},
		setUploading: () => { }
	}
);

function Home() {
	const userID = useSelector((state) => state.userInfo.info?.id || undefined)
	const comID = useSelector((state) => state.userInfo.info?.company || undefined)
	const noticeType = useSelector(state => state.notice.noticeType)

	const companyName = useSelector((state) => state.company.info?.companyName)

	const getComInfo = async () => {
		const res = await dispatch(getCompanyInfo(comID))
	}

	useEffect(() => {
		if (comID !== undefined && !companyName) {
			getComInfo()
		}
	}, [comID, companyName])

	const path = window.location.href;
	const [helloModal, setHelloModal] = useState(false)
	const [tourStart, setTourStart] = useState(false)


	useEffect(() => {
		if (path) {
			const url = new URL(path);
			//* ?hello=true
			const hello = new URLSearchParams(url.search).get("hello");
			const tour = new URLSearchParams(url.search).get("tour")
			if (hello) {
				setHelloModal(true)
			}
			if (tour) {
				setTourStart(true)
			}
		}
	}, [path])



	const navigate = useNavigate()
	const dispatch = useDispatch()

	const onAction = () => {
	}

	const onIdle = async () => {
		const idle = getLastIdleTime()
		const active = getLastActiveTime()
		const timeEnd = new Date().toISOString()
		const duration = getActiveTime()
		//*если не idle то activeTime = getActiveTime()
		//*если idle то activeTime = idle - active

		if (!idle) {
			dispatch(sendWebSession({ userId: userID, comId: comID, timeEnd, duration }))
		} else {
			dispatch(sendWebSession({ userId: userID, comId: comID, timeEnd, duration: active - idle }))
		}
	}


	const { getElapsedTime, getActiveTime, reset, activate, getLastIdleTime, getLastActiveTime } = useIdleTimer({
		onAction,
		onIdle,
		timeout: 2 * 60 * 1000,
		//timeout: 0.5 * 60 * 1000,
		throttle: 500,
		disabled: !userID
	})



	const onCheckToken = async (token) => {
		const id = localStorage.getItem('id')
		if (token) {
			const res = await dispatch(checkToken(token))
			if (res.payload === 406) {
				localStorage.removeItem('token')
				localStorage.removeItem('id')
				toast.error('Your session has expired. Please login again')
				navigate('/')
			}
			if (res.payload === 500 || res.payload === 502) {
				navigate('/502')
			}
			if (res.payload.hello === "world") {
				await openMainDB()
				if (!userID) {
					getUserInfo(token, id)
				}
			}

		}
	}

	const getUserInfo = async (token, id) => {
		const res = await dispatch(fetchUserInfo({ token, id }))
		if (res.payload.error) {
			localStorage.removeItem('token')
			localStorage.removeItem('id')
			navigate('/')
		}
		if (res.payload.status === 'user not found') {
				localStorage.removeItem('token')
				localStorage.removeItem('id')
				navigate('/user-deleted')
		} else if (res.payload.data?.status !== 'ACTIVE') {
			localStorage.removeItem('token')
			localStorage.removeItem('id')
			navigate('/user-deactivated')
		}
	}

	const onUpdateUserAndCompanyInfo = async (token) => {
		const id = localStorage.getItem('id')
		if (token) {
			const res = await dispatch(checkToken(token))
			if (res.payload.hello === "world") {
				await openMainDB()
				const res = await dispatch(fetchUserInfo({ token, id }))
				if (res.payload.status === 'success') {
					await dispatch(getCompanyInfo(res.payload.data.company))
				}
			}

		}
	}

	useEffect(() => {
		const token = localStorage.getItem('token')
		if (token) {
			onCheckToken(token)
		} else if (path === `${window.location.origin}/self-registration` || path === `${window.location.origin}/verification-success`) {
			return
		} else {
			navigate('/')
		}

		const handleMessage = event => {
			if (event.data && event.data.type === 'UPDATE_REQUESTED') {
				if (token) {
					onUpdateUserAndCompanyInfo(token)
				}
			}
		};

		if (navigator.serviceWorker) {
			navigator.serviceWorker.addEventListener('message', handleMessage);
		} else {
			console.log('Service workers are not supported in this browser.');

		}

		return () => {
			if (navigator.serviceWorker) {
				navigator.serviceWorker.removeEventListener('message', handleMessage);
			}
		};
	}, [])



	const winWidth = useWindowWidth()


	//videos for download && upload
	const { Provider } = vidDownloadingContext;
	const [videosForDownload, setVideosForDownload] = useState([])
	const [vidDownloading, setVidDownloading] = useState({})

	const [videosForUpload, setVideosForUpload] = useState([])
	const [vidUploading, setVidUploading] = useState({})
	const [onUpload, setOnUpload] = useState(false)

	const pcVersion = (
		<Provider value={
			{
				vidList: videosForDownload,
				setVidList: setVideosForDownload,
				downloading: vidDownloading,
				setDownloading: setVidDownloading,
				vidsUpload: videosForUpload,
				setVidsUpload: setVideosForUpload,
				uploading: vidUploading,
				setUploading: setVidUploading,
				onUpload,
				setOnUpload
			}
		}>
			<>
				<NoticeOverlay />
				<div style={{ display: 'flex ', overflow: 'hidden', height: '100vh' }}>
					<VideosOverlay videos={videosForDownload} setVideos={setVideosForDownload} downloading={vidDownloading} setDownloading={setVidDownloading} />
					<VideosUploadOverlay
						videos={videosForUpload}
						setVideos={setVideosForUpload}
						uploading={vidUploading}
						setUploading={setVidUploading}
						onUpload={onUpload}
						setOnUpload={setOnUpload}
					/>

					<PCMenu />
					<Outlet />
					<PCLivechat />
					<ToastContainer
						theme='colored'
						position="bottom-right"
						closeButton={false}
						hideProgressBar
						newestOnTop
						closeOnClick
						rtl={false}
						pauseOnFocusLoss={false}
						draggable
						pauseOnHover
					/>
					{helloModal && <HelloModal modalIsOpen={helloModal} setModalIsOpen={setHelloModal} />}
					{tourStart && <GuidedTour />}

				</div>
			</>
		</Provider>
	)

	const mobileVersion = (
		<Provider value={{ vidList: videosForDownload, setVidList: setVideosForDownload, downloading: vidDownloading, setDownloading: setVidDownloading }}>
			<div className={cl.mobPage} >
				<NoticeOverlay />
				<VideosOverlay videos={videosForDownload} setVideos={setVideosForDownload} downloading={vidDownloading} setDownloading={setVidDownloading} />
				<Outlet />
				<MobMenu />
				<ToastContainer
					theme='colored'
					position="bottom-center"
					closeButton={false}
					hideProgressBar
					newestOnTop
					closeOnClick
					rtl={false}
					pauseOnFocusLoss={false}
					draggable
					pauseOnHover
					limit={2}

				/>
				{helloModal && <HelloModal modalIsOpen={helloModal} setModalIsOpen={setHelloModal} />}
			</div>
		</Provider>
	)

	return (
		winWidth > TABLET_WIDTH ? pcVersion : mobileVersion
	)
}

export default Home
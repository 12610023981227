import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { uid } from "uid";
import { StatisticVRService } from "../../services/StatisticVRServices";
import { newPrepareDataForChartByDays, prepareDataForChartByDays, prepareDataForChartByMonth, prepareDataForChartByWeek } from "../../utils/DataForStatistic";

export const getAvgDurationOfTrainingSession = createAsyncThunk(
	'statisticVR/getAvgDurationOfTrainingSession',
	async (comID, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const response = await StatisticVRService.getAvgDurationOfTrainingSession(token, comID);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getTotalVRTrainingSessions = createAsyncThunk(
	'statisticVR/getTotalVRTrainingSessions',
	async (comID, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const response = await StatisticVRService.getTotalVRTrainingSessions(token, comID);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getPercentCompletedToUncompletedTrainings = createAsyncThunk(
	'statisticVR/getPercentCompletedToUncompletedTrainings',
	async (comID, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const response = await StatisticVRService.getPercentCompletedToUncompletedTrainings(token, comID);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getDailyVRStatistic = createAsyncThunk(
	'statisticVR/getDailyVRStatistic',
	async (comID, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const response = await StatisticVRService.getTrainingSessionForWeek(token, comID);
			;
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getWeeklyVRStatistic = createAsyncThunk(
	'statisticVR/getWeeklyVRStatistic',
	async (comID, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const response = await StatisticVRService.getTrainingSessionFor12weeks(token, comID);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getMonthlyVRStatistic = createAsyncThunk(
	'statisticVR/getMonthlyVRStatistic',
	async (comID, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {

			const response = await StatisticVRService.getTrainingSessionFor12months(token, comID);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getTrainingSessions365  = createAsyncThunk(
	'statisticVR/getTrainingSessions365',
	async (comID, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const response = await StatisticVRService.getTrainingSessions365(token, comID);
			return response.data.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getTrainingSessionsTable = createAsyncThunk(
	'statisticVR/getTrainingSessionsTable',
	async (comID, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		try {
			const response = await StatisticVRService.getTrainingSessionsTable(token, comID);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)




const statisticVRAdapter = createEntityAdapter({
	selectId: (statistic) => uid(8)
})

const statisticVRSlice = createSlice({
	name: 'statisticVR',
	initialState: statisticVRAdapter.getInitialState({
		loading: true,
		error: null,
		dailyStatistic: [],
		monthlyStatistic: [],
		weeklyStatistic: [],
		avgDurationTrainingSession: null,
		totalTrainingSession: null,
		completedToUncompletedTrainings: null,
		trainingSessionsTable: null,
		trainingSessions: {
			title: 'Total VR sessions',
			color: "#89A6FB",
			loading: true,
			error: null,
			365: null,
			90: null,
			30: null,
			7: null,
			ticks: null,
			ticksMob: null
		},
	}),
	extraReducers: (builder) => {
		builder
			.addCase(getAvgDurationOfTrainingSession.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(getAvgDurationOfTrainingSession.fulfilled, (state, action) => {
				state.loading = false;
				state.avgDurationTrainingSession = action.payload;
			})
			.addCase(getAvgDurationOfTrainingSession.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getTotalVRTrainingSessions.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(getTotalVRTrainingSessions.fulfilled, (state, action) => {
				state.loading = false;
				state.totalTrainingSession = action.payload;
			})
			.addCase(getTotalVRTrainingSessions.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getPercentCompletedToUncompletedTrainings.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(getPercentCompletedToUncompletedTrainings.fulfilled, (state, action) => {
				state.loading = false;
				state.completedToUncompletedTrainings = action.payload;
			})
			.addCase(getPercentCompletedToUncompletedTrainings.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getDailyVRStatistic.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(getDailyVRStatistic.fulfilled, (state, action) => {
				state.loading = false;
				state.dailyStatistic = prepareDataForChartByDays(action.payload.data)
			})
			.addCase(getDailyVRStatistic.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getWeeklyVRStatistic.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(getWeeklyVRStatistic.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.weeklyStatistic = prepareDataForChartByWeek(payload.data);
			})
			.addCase(getWeeklyVRStatistic.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getMonthlyVRStatistic.pending, (state, { payload }) => {
				state.loading = true;
			})
			.addCase(getMonthlyVRStatistic.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.monthlyStatistic = prepareDataForChartByMonth(payload.data);
			})
			.addCase(getMonthlyVRStatistic.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getTrainingSessionsTable.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(getTrainingSessionsTable.fulfilled, (state, action) => {
				state.loading = false;
				state.trainingSessionsTable = action.payload.data;
			})
			.addCase(getTrainingSessionsTable.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getTrainingSessions365.pending, (state, action) => {
				state.trainingSessions.loading = true;
			})
			.addCase(getTrainingSessions365.fulfilled, (state, action) => {
				state.trainingSessions.loading = false;
				let obj = newPrepareDataForChartByDays(action.payload)
				if(!obj) return
				state.trainingSessions[365] = obj[365]
				state.trainingSessions[90] = obj[90]
				state.trainingSessions[30] = obj[30]
				state.trainingSessions[7] = obj[7]
				state.trainingSessions.error = null
				state.trainingSessions.ticks = obj.ticks
				state.trainingSessions.ticksMob = obj.ticksMob
				state.trainingSessions.loading = false
			})
			.addCase(getTrainingSessions365.rejected, (state, action) => {
				state.trainingSessions.loading = false
				state.trainingSessions.error = action.payload
			})
	}
})

export default statisticVRSlice.reducer;
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { Skeleton } from 'primereact/skeleton';
import { PanelMenu } from 'primereact/panelmenu';

import { logout } from '../../redux/slices/LoginSlice';

import PlatformLinks from './pcMenu/PlatformLinks';
import MenuItemExpanded from './pcMenu/MenuItemExpanded';

import PlanChips from '../../UI/planChips/PlanChips';
import ContentModal from '../../UI/modal/ContentModal'
import MenuLink from '../../UI/menu/links/MenuLink'
import Modal from '../../UI/modal/Modal'
import { Caption } from './../../UI/Typography/Typography';
import SimpleCompanyLogo from '../../UI/simpleCompanyLogo/SimpleCompanyLogo';

import logo from '../../assets/logo_gray.svg'

import cl from '../../styles/components/menu/PCMenu.module.css'

function PCMenu() {
	const dispatch = useDispatch()
	const [accordionActive, setAccordionActive] = useState(0)
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const navigate = useNavigate()
	const iRole = useSelector((state) => state.userInfo?.info.role)

	const info = useSelector((state) => state.company.info)
	const subscrInfo = useSelector(state => state.subscription.info)
	const id = localStorage.getItem('id')

	const onLogout = async () => {

		localStorage.removeItem('token')
		localStorage.removeItem('email')
		localStorage.removeItem('id')
		navigate('/', 'replace')
		dispatch(logout());
		//window.location.reload()
	}

	let location = useLocation();

	const [path, setPath] = useState(location.pathname)

	useEffect(() => {
		setPath(location.pathname)
	}, [location]);

	const redirectToLanding = () => {
		window.open('https://metaenga.com/', "_blank")
	}

	const itemRenderer = (item, options) => (
		<MenuLink title={item.label} iconClass={item.icon} path={item.path} inside={item.inside} location={path}>{item.path === 'subscription' && subscrInfo?.plan && <PlanChips plan={subscrInfo?.plan} light  />}</MenuLink>
	);

	const expandedItemRenderer = (item, options) => (
		<MenuItemExpanded
			id={item.id || item.label}
			title={item.label}
			iconClass={item.icon}
			//active={options.active}
			active={false}
			location={path}
			pathArr={item.pathArr}
		>
		</MenuItemExpanded>
	);

	const onSetKeys = (key) => {
		if (expandedKeys[key]) {
			setExpandedKeys({})
		} else {
			setExpandedKeys({ [key]: true })
		}
	}

	const [expandedKeys, setExpandedKeys] = useState({});

	const onSetExpandedKeys = (key) => {
		setExpandedKeys({ [key]: true })
	}

	const onCheckPath = (path) => {
		if (path.includes('vr') || path.includes('videos')) {
			setExpandedKeys({ library: true })
		} else if (path.includes('team')) {
			setExpandedKeys({ team: true })
		} else if (path.includes('settings')) {
			setExpandedKeys({ settings: true })
		} else if (path.includes('insights')) {
			setExpandedKeys({ insights: true })
		}
	}

	useEffect(() => {
		if (location) {
			onCheckPath(location.pathname)
		}
	}, [])



	const items = [
		{
			label: 'Library',
			id: 'library',
			key: 'library',
			icon: 'icon-video_library',
			template: expandedItemRenderer,
			command: () => onSetKeys('library'),
			pathArr: ['vr', 'videos'],
			items: [
				{
					label: 'VR apps',
					path: 'vr',
					template: itemRenderer,
					expanded: false,
					inside: true,
					command: () => onSetExpandedKeys('library')
				},
				{
					label: '360 video',
					path: 'videos',
					template: itemRenderer,
					expanded: false,
					inside: true,
					command: () => onSetExpandedKeys('library')
				}
			]
		},
		{
			label: 'Classroom',
			key: 'classrooms',
			icon: 'icon-user_group',
			path: 'classrooms',
			template: itemRenderer,
			expanded: false,
			command: () => setExpandedKeys({}),
		},
		{
			label: 'Team management',
			id: 'team',
			key: 'team',
			icon: 'icon-user_manage',
			template: expandedItemRenderer,
			command: () => onSetKeys('team'),
			pathArr: ['users', 'teams', 'devices'],
			items: [
				{
					label: 'Users',
					path: 'team/users',
					template: itemRenderer,
					expanded: false,
					inside: true,
					command: () => onSetExpandedKeys('team'),
				},
				{
					label: 'Teams',
					path: 'team/teams',
					template: itemRenderer,
					expanded: false,
					inside: true,
					command: () => onSetExpandedKeys('team'),
				},
				{
					label: 'Devices',
					path: 'team/devices',
					template: itemRenderer,
					expanded: false,
					inside: true,
					command: () => onSetExpandedKeys('team'),
				}
			]
		},
		{
			label: 'Insights',
			id: 'insights',
			key: 'insights',
			icon: 'icon-dashboard',
			template: expandedItemRenderer,
			command: () => onSetKeys('insights'),
			pathArr: ['insights', 'my-insights'],
			items: [
				{
					label: 'Company',
					path: 'insights',
					template: itemRenderer,
					expanded: false,
					inside: true,
					command: () => onSetExpandedKeys('insights'),
				},
				{
					label: 'Personal',
					path: 'my-insights',
					template: itemRenderer,
					expanded: false,
					inside: true,
					command: () => onSetExpandedKeys('insights'),
				}
			]
		},
		{
			label: 'Settings',
			id: 'settings',
			key: 'settings',
			icon: 'icon-settings',
			template: expandedItemRenderer,
			command: () => onSetKeys('settings'),
			pathArr: ['settings'],
			items: [
				{
					label: 'Company profile',
					path: 'settings/company',
					template: itemRenderer,
					expanded: false,
					inside: true,
					command: () => onSetExpandedKeys('settings'),
				},
				{
					label: 'Account',
					path: `settings/${id}`,
					template: itemRenderer,
					expanded: false,
					inside: true,
					command: () => onSetExpandedKeys('settings'),
				}
			]
		},
		{
			label: 'Subscription',
			key: 'subscription',
			id: 'subscription',
			path: 'subscription',
			icon: 'icon-list-box',
			template: itemRenderer,
			expanded: false,
			command: () => setExpandedKeys({}),
		}
	]

	const admin = [
		{
			label: 'Library',
			id: 'library',
			key: 'library',
			icon: 'icon-video_library',
			template: expandedItemRenderer,
			command: () => onSetKeys('library'),
			pathArr: ['vr', 'videos'],
			items: [
				{
					label: 'VR apps',
					path: 'vr',
					template: itemRenderer,
					expanded: false,
					inside: true,
					command: () => onSetExpandedKeys('library')
				},
				{
					label: '360 video',
					path: 'videos',
					template: itemRenderer,
					expanded: false,
					inside: true,
					command: () => onSetExpandedKeys('library')
				}
			]
		},
		{
			label: 'Classroom',
			key: 'classrooms',
			icon: 'icon-user_group',
			path: 'classrooms',
			template: itemRenderer,
			expanded: false,
			command: () => setExpandedKeys({}),
		},
		{
			label: 'Team management',
			id: 'team',
			key: 'team',
			icon: 'icon-user_manage',
			template: expandedItemRenderer,
			command: () => onSetKeys('team'),
			pathArr: ['users', 'teams', 'devices'],
			items: [
				{
					label: 'Users',
					path: 'team/users',
					template: itemRenderer,
					expanded: false,
					inside: true,
					command: () => onSetExpandedKeys('team'),
				},
				{
					label: 'Teams',
					path: 'team/teams',
					template: itemRenderer,
					expanded: false,
					inside: true,
					command: () => onSetExpandedKeys('team'),
				},
				{
					label: 'Devices',
					path: 'team/devices',
					template: itemRenderer,
					expanded: false,
					inside: true,
					command: () => onSetExpandedKeys('team'),
				}
			]
		},
		{
			label: 'Insights',
			id: 'insights',
			key: 'insights',
			icon: 'icon-dashboard',
			template: expandedItemRenderer,
			command: () => onSetKeys('insights'),
			pathArr: ['insights', 'my-insights'],
			items: [
				{
					label: 'Company',
					path: 'insights',
					template: itemRenderer,
					expanded: false,
					inside: true,
					command: () => onSetExpandedKeys('insights'),
				},
				{
					label: 'Personal',
					path: 'my-insights',
					template: itemRenderer,
					expanded: false,
					inside: true,
					command: () => onSetExpandedKeys('insights'),
				}
			]
		},
		{
			label: 'Settings',
			id: 'settings',
			key: 'settings',
			icon: 'icon-settings',
			template: expandedItemRenderer,
			command: () => onSetKeys('settings'),
			pathArr: ['settings'],
			items: [
				{
					label: 'Company profile',
					path: 'settings/company',
					template: itemRenderer,
					expanded: false,
					inside: true,
					command: () => onSetExpandedKeys('settings'),
				},
				{
					label: 'Account',
					path: `settings/${id}`,
					template: itemRenderer,
					expanded: false,
					inside: true,
					command: () => onSetExpandedKeys('settings'),
				}
			]
		}
	]

	const enhanced = [
		{
			label: 'Library',
			id: 'library',
			key: 'library',
			icon: 'icon-video_library',
			template: expandedItemRenderer,
			command: () => onSetKeys('library'),
			pathArr: ['vr', 'videos'],
			items: [
				{
					label: 'VR apps',
					path: 'vr',
					template: itemRenderer,
					expanded: false,
					inside: true,
					command: () => onSetExpandedKeys('library')
				},
				{
					label: '360 video',
					path: 'videos',
					template: itemRenderer,
					expanded: false,
					inside: true,
					command: () => onSetExpandedKeys('library')
				}
			]
		},
		{
			label: 'Insights',
			id: 'insights',
			key: 'insights',
			icon: 'icon-dashboard',
			template: expandedItemRenderer,
			command: () => onSetKeys('insights'),
			pathArr: ['insights', 'my-insights'],
			items: [
				{
					label: 'Personal',
					path: 'my-insights',
					template: itemRenderer,
					expanded: false,
					inside: true,
					command: () => onSetExpandedKeys('insights'),
				}
			]
		},
		{
			label: 'Settings',
			id: 'settings',
			key: 'settings',
			icon: 'icon-settings',
			template: expandedItemRenderer,
			command: () => onSetKeys('settings'),
			pathArr: ['settings'],
			items: [
				{
					label: 'Account',
					path: `settings/${id}`,
					template: itemRenderer,
					expanded: false,
					inside: true,
					command: () => onSetExpandedKeys('settings'),
				}
			]
		}
	]




	return (
		<aside className={cl.aside} >
			<div className={cl.logo} onClick={() => navigate('/home/vr', 'replace')}>
				<SimpleCompanyLogo companyName={info?.companyName} logo={info?.logo} />

			</div>
			<div className={cl.flex__item}>
				<menu className={cl.menu}>
					{iRole ?
						<PanelMenu
							model={iRole === 'ENHANCED' ? enhanced : iRole === 'ADMIN' ? admin : iRole === 'OWNER' ? items : []}
							expandedKeys={expandedKeys}
						/>

						: <div>
							<div style={{ marginBottom: 2 }}>
								<Skeleton width="100%" height="44px" ></Skeleton>
							</div>
							<div style={{ marginBottom: 2 }}>
								<Skeleton width="100%" height="44px" ></Skeleton>
							</div>
							<div style={{ marginBottom: 2 }}>
								<Skeleton width="100%" height="44px" ></Skeleton>
							</div>
							<div style={{ marginBottom: 2 }}>
								<Skeleton width="100%" height="44px" ></Skeleton>
							</div>

						</div>

					}

					<div>
						<MenuLink title='Support' iconClass='icon-help_circle' onClick={() => window.open('https://digitalengineeringmagic.atlassian.net/servicedesk/customer/portals', "_blank")} />
					</div>


					<div style={{ marginTop: 34 }}>
						<MenuLink title='Log out' iconClass='icon-log-out' onClick={() => setModalIsOpen(true)} />
					</div>
				</menu>

			</div>
			<PlatformLinks />
			<div className={cl.gray__logo}>
				<Caption>Powered by</Caption>
				<img src={logo} alt='metaenga logo' onClick={redirectToLanding} />
			</div>
			<Modal modalIsOpen={modalIsOpen} setIsOpen={setModalIsOpen}>
				<ContentModal header='Log out of Metaenga?' textButton='Log out' setModalIsOpen={setModalIsOpen} onConfirm={onLogout} />
			</Modal>
		</aside >
	)
}

export default PCMenu
import { openDB } from "idb"
import { toast } from "react-toastify";
import { VideoDB } from "../actions/actionsVideoDB";
import { VideoListDB } from "../actions/actionsVideoList";


const DB_NAME = 'personalVideoDB';
const DB_VERSION = 3;
const VIDEO_STORE_NAME = 'videos';
const VIDEO_LIST_STORE_NAME = 'videoList';

async function upgradeToVersion1(db) {
	db.createObjectStore(VIDEO_STORE_NAME);
	db.createObjectStore(VIDEO_LIST_STORE_NAME);
}

async function upgradeToVersion2(db) {
	//db.createObjectStore('newObjectStore');
	getPersonalDB('addVideoList', { videoList: [] })
	// Add any other necessary upgrades here
}

async function upgradeToVersion3(db) {
	//clean all trash stores from openDB
	const objectStoreNames = db.objectStoreNames;
	for (let i = 0; i < objectStoreNames.length; i++) {
	  db.deleteObjectStore(objectStoreNames[i]);
	}
	//create new stores
	db.createObjectStore(VIDEO_STORE_NAME);
	db.createObjectStore(VIDEO_LIST_STORE_NAME);
	getPersonalDB('addVideoList', { videoList: [] })
}

export async function createPersonalDB() {
	const id = await localStorage.getItem('id');
	if (!id) {
		throw new Error('No user ID found in localStorage.');
	}

	try {
		const db = await openDB(`${DB_NAME}-${id}`, DB_VERSION, {
			upgrade(db, oldVersion) {
				switch (oldVersion) {
					case 0:
						upgradeToVersion1(db);
						break;
					case 1:
						upgradeToVersion2(db);
						break;
					case 2:
						upgradeToVersion3(db);
						break;
					default:
						;
						break;
				}
			},
		});

		;
		return db;
	} catch (error) {
		toast.error(error.message)
		console.error(`Failed to create or upgrade database: ${error}`);
	}
}

export async function getPersonalDB(type, data = {}) {
	const id = await localStorage.getItem('id');
	if (!id) {
		throw new Error('No user ID found in localStorage.');
	}

	try {
		const db = await openDB(`${DB_NAME}-${id}`, DB_VERSION);

		switch (type) {
			case 'addVideo':
				await VideoDB.addVideo(db, data.videoID, data.blob);
				break;
			case 'getVideo':
				const video = await VideoDB.getVideo(db, data.videoID);
				return video;
			case 'getAllVideos':
				const videosList = await VideoDB.getAllVideos(db);
				return videosList;
			case 'addVideoList':
				await VideoListDB.addVideoList(db, data.videoList);
				break;
			case 'getVideoList':
				const res = await VideoListDB.getVideoList(db);
				return res;
			case 'removeVideo':
				const remove = await VideoDB.removeVideo(db, data);
				return remove;
			default:
				break;
		}

		return db;
	} catch (error) {
		toast.error(error.message)
		console.error(`Failed to get database: ${error}`);
	}
}

//export async function createPersonalDB() {
//	const id = await localStorage.getItem('id')
//	const db = await openDB(id, 1, {
//		upgrade(db, oldVersion) {
//			switch (oldVersion) {
//				case 0:
//					const res = db.createObjectStore('videos');
//					;
//					db.createObjectStore('videoList'); // list for downloading
//				case 1:
//					debugger
//					db.createObjectStore('videos');
//					db.createObjectStore('videoList');
//				case 2:
//					getPersonalDB('addVideoList', { videoList: [] })
//				default:
//					;
//					break;
//			}
//		}
//	})
//}

//export async function getPersonalDB(type, data = {}) {
//	const id = await localStorage.getItem('id')
//	if (!id) return
//	const db = await openDB(id, 1)
//	;
//	switch (type) {
//		case 'addVideo':
//			VideoDB.addVideo(db, data.videoID, data.blob)
//			break;
//		case 'getVideo':
//			let video = await VideoDB.getVideo(db, data.videoID)
//			//;
//			return video
//		case 'getAllVideos':
//			let videosList = await VideoDB.getAllVideos(db)
//			//;
//			return videosList
//		case 'addVideoList':
//			VideoListDB.addVideoList(db, data.videoList)
//			break;
//		case 'getVideoList':
//			let res = await VideoListDB.getVideoList(db)
//			//;
//			return res
//		case 'removeVideo':
//			;
//			let remove = await VideoDB.removeVideo(db, data)
//			//;
//			return remove
//		default:
//			break;
//	}
//}
import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import cl from './Button.module.css'
import Spinner from '../spinner/Spinner'

function Button({ children, onClick, left = false, right = false, icon = '', textbtn = false, linkbtn = false, secondary = false, texthover = false, className = '', loading = false, ...props }) {
	const [svg, setSvg] = useState('')

	useEffect(() => {
		if (icon === 'plus') {
			setSvg('plus')
		} else if (icon === 'upload') {
			setSvg('upload')
		} else if (icon === 'download') {
			setSvg('file-download')
		} else if (icon === 'downloaded') {
			setSvg('offline_pin')
		} else if (icon === 'start') {
			setSvg('start_session')
		} else if (icon === 'pause') {
			setSvg('pause_circle')
		} else if (icon === 'play') {
			setSvg('play_circle')
		} else if (icon === 'down') {
			setSvg('chevron-down')
		} else if (icon === 'up') {
			setSvg('chevron-up')
		} else if (icon === 'right') {
			setSvg('chevron-right')
		} else if (icon === 'left') {
			setSvg('chevron-left')
		} else if (icon === 'end') {
			setSvg('stop_session')
		} else if (icon === 'edit') {
			setSvg('edit')
		} else if (icon === 'addCR') {
			setSvg('user_group')
		} else if (icon === 'assign') {
			setSvg('user-add')
		}
	}, [icon])

	const styleGuide = secondary ? cl.secondary : textbtn ? cl.textbtn : linkbtn ? cl.linkbtn : texthover ? cl.texthover : cl.primary

	const buttonDisabled = <React.Fragment>
		<button onClick={onClick} className={classNames(cl.btn, styleGuide, className)} disabled {...props}>
			{left && <span className={classNames(cl.icon, cl.left)}><i className={`icon-${svg}`}></i></span>}
			{children}
			{right && <span className={classNames(cl.icon, cl.right)}><i className={`icon-${svg}`}></i></span>}
			{loading && <div className={cl.spinner}><Spinner width={30} /></div>}
		</button>
	</React.Fragment>

	const buttonEnabled = <React.Fragment>
		<button onClick={onClick} className={classNames(cl.btn, styleGuide, className)} {...props}>
			{left && icon && <span className={classNames(cl.icon, cl.left)}><i className={`icon-${svg}`}></i></span>}
			{children}
			{right && icon && <span className={classNames(cl.icon, cl.right)}><i className={`icon-${svg}`}></i></span>}
		</button>
	</React.Fragment>

	return (
		loading || props.disabled ? buttonDisabled : buttonEnabled
	)
}

export default Button
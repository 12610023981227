import { useNavigate } from 'react-router-dom';

import ContentModal from '../../../UI/modal/ContentModal';
import Modal from '../../../UI/modal/Modal'
import { Paragraph } from '../../../UI/Typography/Typography'

import cl from '../home/HelloModal.module.css'

function NoticePurchaseModal({ modalIsOpen, setModalIsOpen, onConfirm, onClose }) {
	return (
		<Modal callback={onClose} style={{ maxWidth: 440, width: '90%' }} modalIsOpen={modalIsOpen} setIsOpen={setModalIsOpen}>
			<ContentModal

				header='Continue your purchase'
				textButton='Continue'
				modalIsOpen={modalIsOpen}
				setModalIsOpen={setModalIsOpen}
				onConfirm={onConfirm}
				onClose={onClose}
			>
				<div className={cl.content}>
					<Paragraph >
						It looks like you were purchasing a <span style={{whiteSpace: 'nowrap'}}>Flex subscription.</span> Would you like to continue 
						to the page with your selected modules?
					</Paragraph>
				</div>
			</ContentModal>
		</Modal>
	)
}

export default NoticePurchaseModal
import classNames from 'classnames'

import cl from './Typography.module.css'


export function HeaderBig({ children, ...props }) {
	return (
		<h1 {...props} className={classNames(cl.big, props.className)}>{children}</h1>
	)
}

export function HeaderMid({ children, ...props }) {
	return (
		<h2 {...props} className={classNames(cl.mid, props.className)}>{children}</h2>
	)
}

export function Header({ children, ...props }) {
	return (
		<h3 {...props} className={classNames(cl.header, props.className)}>{children}</h3>
	)
}

export function Subheader({ children, ...props }) {
	return (
		<h4 {...props} className={classNames(cl.subheader, props.className)}>{children}</h4>
	)
}

export function Paragraph({ children, ...props }) {
	return (
		<p {...props} className={classNames(cl.paragraph, props.className)}>{children}</p>
	)
}

export function Caption({ children, ...props }) {
	return (
		<p {...props} className={classNames(cl.caption, props.className)}>{children}</p>
	)
}

export function TextError({ children, ...props }) {
	return (
		<p {...props} className={classNames(cl.texterror, props.className)}>{children}</p>
	)
}
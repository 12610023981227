import { useEffect, useState } from 'react'

import cl from './SimpleCompanyLogo.module.css'
import { Paragraph } from '../Typography/Typography'
import Spinner from '../spinner/Spinner'

function SimpleCompanyLogo({ companyName = '', logo = '' }) {
	const [shortName, setShortName] = useState('')

	useEffect(() => {
		if (!logo) {
			let short= companyName.split(' ').map(word => word[0])
			if (short.length > 2) {
				short = short[0] + short[short.length - 1]
			} else {
				short = short.join('')
			}
			setShortName(short.toUpperCase())
			//setShortName(companyName.split(' ').map(word => word[0]).join('').toUpperCase())
		}
	}, [companyName, logo])

	const logoLink = process.env.REACT_APP_MAIN_API_URL + `/logo/${logo}`

	return (
		<div className={cl.logo__wrapper}>
			<div className={cl.logo__container}>
				<div className={cl.logo__img}>
					{logo ? logoLink ? <img src={logoLink} alt="logo" /> : <Spinner /> : shortName ?
						<Paragraph>{shortName}</Paragraph> : <Spinner width={25} />}

				</div>
			</div>
		</div>
	)
}

export default SimpleCompanyLogo
import React from 'react'
import ContentModal from '../../../UI/modal/ContentModal'
import Modal from '../../../UI/modal/Modal'
import { Paragraph } from '../../../UI/Typography/Typography'
import PlatformLinks from '../../menu/pcMenu/PlatformLinks';
import ConfettiExplosion from 'react-confetti-explosion'

import cl from './HelloModal.module.css'
import PlatformLink from '../../menu/pcMenu/PlatformLink';

function HelloModal({ modalIsOpen, setModalIsOpen }) {

	const onConfirm = () => {
		window.open("https://www.oculus.com/experiences/quest/4950000178436188", "_blank")
	}

	const onClose = () => {
		// Получаем текущий URL
		const urlString = window.location.href;
		const url = new URL(urlString);

		// Получаем объект с параметрами
		const params = new URLSearchParams(url.search);

		// Удаляем параметр по его имени
		const paramNameToRemove = "hello";
		params.delete(paramNameToRemove);

		// Добавляем параметр 'tour', здесь можно установить нужное значение
		const tourValue = "start"; // Пример значения, измените по необходимости
		params.set("tour", tourValue);

		// Обновляем URL с новыми параметрами
		const newUrl = `${url.origin}${url.pathname}${params.toString() ? '?' + params.toString() : ''}`;


		//// Обновляем URL без удаленного параметра
		//const newUrl = `${url.origin}${url.pathname}${params.length > 0 ? '?' + params.toString() : ''}`;

		// Используем новый URL или перенаправляем на него
		window.history.replaceState({}, "", newUrl)
		setModalIsOpen(false)
	}

	return (
		<Modal callback={onClose} style={{ maxWidth: 440, width: '90%' }} modalIsOpen={modalIsOpen} setIsOpen={setModalIsOpen}>
			<ContentModal
				header='Welcome to Metaenga'
				textButton='Got it'
				modalIsOpen={modalIsOpen}
				setModalIsOpen={setModalIsOpen}
				closeOnConfirm={false}
				lite
				onConfirm={onClose}
				onClose={onClose}
			>
				<div className={cl.content}>
					<ConfettiExplosion zIndex={1000} />
					<Paragraph style={{ fontWeight: 600 }}>
						You now have a Free subscription.
					</Paragraph>
					<Paragraph>To enjoy the full experience, please download XR Platform app for your headset:</Paragraph>


					<div className={cl.links}>
						<PlatformLink />
						<PlatformLink platform='piko' />
					</div>

				</div>
			</ContentModal>
		</Modal>
	)
}

export default HelloModal
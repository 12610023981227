import React from 'react'

import cl from './Spinner.module.css'
import spin from '../../assets/icons/spinner.svg'
import spinW from '../../assets/icons/spinnerWhite.svg'

function Spinner({ width = 50, white = false }) {
	return (
		<div style={{ width: width, height: width }} className={cl.container}>
			{white ?
				<img src={spinW} alt="spinner" />
				: <img src={spin} alt="spinner" />}
		</div>
	)
}

export default Spinner
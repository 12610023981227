import classNames from 'classnames'
import React from 'react'
import Button from '../Button/Button'

import { Header } from '../Typography/Typography'

import cl from './ContentModal.module.css'

function ContentModal({ setModalIsOpen, header, closeOnConfirm = true, onConfirm, onClose, textButton = 'OK', closeButton = 'Cancel', loading = false, disabled = false, children, lite = false, ...props }) {

	const onClick = () => {
		closeOnConfirm && setModalIsOpen(false)
		onConfirm()
	}
	const onCancel = () => {
		setModalIsOpen(false)
		onClose && onClose()
	}
	return (
		<div className={cl.container} {...props}>
			<Header> {header} </Header>
			{children}
			<div className={cl.buttons}>
				{!lite && <Button secondary onClick={onCancel}>{closeButton}</Button>}
				<Button disabled={disabled || loading} loading={loading} onClick={onClick}>{textButton}</Button>
			</div>
		</div>
	)
}

export default ContentModal
import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { uid } from "uid";
import { StatisticPersonalService } from "../../services/StatisticPersonalServices";
import { prepareDataForChartByDays, prepareDataForChartByMonth, prepareDataForChartByWeek } from "../../utils/DataForStatistic";

export const getPersonalDailyTrainingStatistic = createAsyncThunk(
	'statisticPersonalTraining/getPersonalDailyTrainingStatistic',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		const { userId, comID } = data;
		try {
			const response = await StatisticPersonalService.getDailyDurationTrainingStatistic(token, comID, userId);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getPersonalWeeklyTrainingStatistic = createAsyncThunk(
	'statisticPersonalTraining/getPersonalWeeklyTrainingStatistic',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		const { userId, comID } = data;
		try {
			const response = await StatisticPersonalService.getWeeklyDurationTrainingStatistic(token, comID, userId);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getPersonalMonthlyTrainingStatistic = createAsyncThunk(
	'statisticPersonalTraining/getPersonalMonthlyTrainingStatistic',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		const { userId, comID } = data;
		try {
			const response = await StatisticPersonalService.getMonthlyDurationTrainingStatistic(token, comID, userId);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getPersonalTrainingTable = createAsyncThunk(
	'statisticPersonalTraining/getPersonalTrainingTable',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		const { userId, comID } = data;
		try {
			const response = await StatisticPersonalService.getVRTrainingData(token, comID, userId);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)

export const getSessionListByTrainingId = createAsyncThunk(
	'statisticPersonalTraining/getSessionListByTrainingId',
	async (data, { rejectWithValue }) => {
		const token = localStorage.getItem('token');
		const { userId, comID, trainingId, users } = data;
		try {
			const response = await StatisticPersonalService.getVRSessionsByTraining(token, comID, userId, trainingId);
			return response.data;
		} catch (e) {
			return rejectWithValue(e.response.data);
		}
	}
)


const statisticPersonalTrainingAdapter = createEntityAdapter({
	selectId: (statistic) => uid(8)
})

const statisticPersonalTrainingSlice = createSlice({
	name: 'statisticPersonalTraining',
	initialState: statisticPersonalTrainingAdapter.getInitialState({
		loading: false,
		error: null,
		dailyStatistic: [],
		weeklyStatistic: [],
		monthlyStatistic: [],
		trainingTable: null,
		sessions: null
	}),
	extraReducers: (builder) => {
		builder 
			.addCase(getPersonalDailyTrainingStatistic.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getPersonalDailyTrainingStatistic.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
				state.dailyStatistic = prepareDataForChartByDays(action.payload);
			})
			.addCase(getPersonalDailyTrainingStatistic.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getPersonalWeeklyTrainingStatistic.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getPersonalWeeklyTrainingStatistic.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
				state.weeklyStatistic = prepareDataForChartByWeek(action.payload);
			})
			.addCase(getPersonalWeeklyTrainingStatistic.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getPersonalMonthlyTrainingStatistic.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getPersonalMonthlyTrainingStatistic.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
				state.monthlyStatistic = prepareDataForChartByMonth(action.payload);
			})
			.addCase(getPersonalMonthlyTrainingStatistic.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getPersonalTrainingTable.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getPersonalTrainingTable.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;
				state.trainingTable = action.payload;
			})
			.addCase(getPersonalTrainingTable.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})

			.addCase(getSessionListByTrainingId.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getSessionListByTrainingId.fulfilled, (state, action) => {
				state.loading = false;
				state.error = null;

				let users = action.meta.arg.users;
				let sessions = action.payload.data;
				sessions = sessions.map((session) => {
					let user = users.find((user) => user.id === session.userId);
					if(!user) return  {
						...session,
						userEmail: 'User not found',
						userName: 'User not found',
						userAvatar: '',
						userId: 'User not found'
					};
					return {
						...session,
						shortId: session.trainingSessionId.split("").splice(session.trainingSessionId.length - 6, 6).join(""),
						userEmail: user.email,
						userName: user.name,
						userAvatar: user.avatar,
						userId: user.id,
					}
				})
				state.sessions = {
					...state.sessions,
					[action.meta.arg.trainingId]: sessions
				};
			})
			.addCase(getSessionListByTrainingId.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
	}
})


export default statisticPersonalTrainingSlice.reducer;
import classNames from 'classnames'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { Paragraph } from '../../Typography/Typography'
import cl from '../accordion/AccordionItem.module.css'


const MenuLink = React.memo(({ title, iconClass = '', path, inside, children, ...props }) => (

	path
		? <NavLink className={({ isActive }) =>
			isActive ? classNames(cl.title, cl.active) : cl.title
		}  to={path ? path : ''} style={inside ? {paddingLeft: 64} : {}} {...props}>
			{iconClass && <i className={iconClass} />}
			<Paragraph>{title}</Paragraph>
			{children}
		</NavLink>
		: <div className={cl.title} {...props}>
			{iconClass && <i className={iconClass} />}
			<Paragraph>{title}</Paragraph>
			{children}
		</div>

))

export default MenuLink